import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OrderByPipe } from './pipes/order-by.pipe';
import { OrderValueByPipe } from './pipes/order-value-by.pipe';
import { MasteryPipe } from './pipes/mastery.pipe';
import { KeywordPipe } from './pipes/keyword.pipe';
import { BreakoutPipe } from './pipes/breakout.pipe';
import { ServersComponent } from './servers/servers.component';
import { UsersComponent } from './users/users.component';
import { CharactersComponent } from './characters/characters.component';
import { AbilitiesComponent } from './abilities/abilities.component';

import { ServerService } from './servers/server.service';

import { BackButtonDirective } from './directives/back-button.directive';
import { AbilitySheet } from './abilities/ability.sheet';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialModule } from './material.module';
import { MatIconModule } from '@angular/material/icon';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    OrderByPipe,
    OrderValueByPipe,
    MasteryPipe,
    KeywordPipe,
    BreakoutPipe,
    ServersComponent,
    UsersComponent,
    CharactersComponent,
    AbilitiesComponent,
    BackButtonDirective,
    AbilitySheet
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    DragDropModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule
  ],
  providers: [
    ServerService,
  ],
  entryComponents: [
      AbilitySheet
  ],
  bootstrap: [
      AppComponent,
  ]
})
export class AppModule { }
