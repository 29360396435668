import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ServerService } from '../servers/server.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: Observable<any>;
  characters = [];
  serverId: string;

  constructor(
      private route: ActivatedRoute,
      public location: Location,
      private serverService: ServerService
  ) { }

  ngOnInit(): void {
    this.serverId = this.route.snapshot.paramMap.get('serverId');
    // this.users = this.serverService.getServer(this.serverId);
    this.serverService.getServer(this.serverId).subscribe((users: object): void => {
          const characterArray = [];
          for (const userKey in users) {
            for (const characterKey in users[userKey].characters) {
              characterArray.push(users[userKey].characters[characterKey]);
            }
          }
          this.characters = characterArray;
        }
    );
  }
}
