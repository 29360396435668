import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mastery' })
export class MasteryPipe implements PipeTransform {

    transform(value: number, keyword: number = null): string {
        let prefix = '';
        if (keyword) {
            value =  value - keyword;
            prefix = '+';
        }
        const masteries = Math.floor((value - 1) / 20);
        let rating;
        if (value % 20 === 0) {
            rating = 20;
        } else {
            rating = value % 20;
        }
        switch (masteries) {
            case 0:
                return `${prefix}${rating}`;
                break;
            case 1:
                return `${prefix}${rating}<span class="rune">W</span>`;
                break;
            default:
                return `${prefix}${rating}<span class="rune">W</span>${masteries}`;
                break;
        }
    }
}
