import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {ServerService} from '../servers/server.service';
import { Observable } from 'rxjs';
import { User } from '../users/user';

@Component({
  selector: 'app-characters',
  templateUrl: './characters.component.html',
  styleUrls: ['./characters.component.scss']
})
export class CharactersComponent implements OnInit {

  user: User;
  characters: Observable<any>;
  serverId: string;
  userId: string;

  constructor(
      private route: ActivatedRoute,
      public location: Location,
      private serverService: ServerService
  ) { }

  ngOnInit(): void {
    this.serverId = this.route.snapshot.paramMap.get('serverId');
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.serverService.getUser(this.serverId, this.userId).subscribe((user: User): void => {
      this.user = user;
    });
    this.characters = this.serverService.getCharacters(this.serverId, this.userId);
  }
}
