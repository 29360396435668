import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {ServerService} from '../servers/server.service';
import {MatBottomSheet} from '@angular/material';
import {AbilitySheet} from './ability.sheet';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-abilities',
  templateUrl: './abilities.component.html',
  styleUrls: ['./abilities.component.scss']
})
export class AbilitiesComponent implements OnInit {

  serverId: string;
  userId: string;
  characterName: string;
  abilities: object;

  constructor(
      private route: ActivatedRoute,
      public location: Location,
      private serverService: ServerService,
      private bottomSheet: MatBottomSheet,
      private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.serverId = this.route.snapshot.paramMap.get('serverId');
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.characterName = this.route.snapshot.paramMap.get('characterName');
    this.serverService.getAbilities(this.serverId, this.userId, this.characterName).subscribe((abilities: object): void => {
          this.abilities = abilities;
        }
    );
  }

  displayAbilityActions(ability): void {
    this.bottomSheet.open(AbilitySheet, {
      data: {
        ability,
        abilities: this.abilities,
        serverId: this.serverId,
        userId: this.userId,
        characterName: this.characterName,
      }
    });
  }

  addKeyword(): void {
    const keyword = {
      name: 'New keyword ability',
      value: 16,
    };
    this.serverService.addAbility(this.serverId, this.userId, this.characterName, keyword);
    // this.snackBar.open(`${keyword.name} added`, null, {
    //   duration: 3000,
    // });
    this.displayAbilityActions(keyword);
  }

  drop(event: CdkDragDrop<string[]>) {
    return;
    // console.log(event.previousIndex);
    // console.log(event.currentIndex);
    // console.log(this.abilities);
    // moveItemInArray(this.abilities, event.previousIndex, event.currentIndex);
    // console.log(this.abilities);
    // for (let i; this.abilities.length < i; i++) {
    //
    // }
    // if (event.previousIndex !== event.currentIndex) {
    //   console.log(event);
    //
    //   if (event.previousIndex < event.currentIndex) {
    //
    //     for (let i = event.previousIndex + 1; event.currentIndex < i; i++) {
    //       if (i < event.currentIndex) {
    //         this.abilities[i].order--;
    //       } else if (i === event.currentIndex) {
    //         this.abilities[event.previousIndex].order = event.currentIndex;
    //       }
    //     }
    //
    //   }
    // }
    // if (event.previousIndex !== event.currentIndex) {
    //   console.log(event);
    //
    //   if (event.previousIndex < event.currentIndex) {
    //     for (let i = event.previousIndex + 1; event.currentIndex < i; i++) {
    //       if (i < event.currentIndex) {
    //         this.abilities[i].order--;
    //       } else if (i === event.currentIndex) {
    //         this.abilities[event.previousIndex].order = event.currentIndex;
    //       }
    //     }
    //   }
    // }
  }
}
