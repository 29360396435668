import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ServerService } from '../servers/server.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Ability } from '../abilities/ability';

@Component({
    selector: 'ability-sheet',
    templateUrl: './ability.sheet.html',
})
export class AbilitySheet implements OnInit {

    key = null;

    constructor(
        private bottomSheetRef: MatBottomSheetRef<AbilitySheet>,
        private serverService: ServerService,
        private snackBar: MatSnackBar,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
            ability: Ability,
            abilities: object,
            serverId: string,
            userId: string,
            characterName: string,
        },
    ) { }

    ngOnInit(): void {
        this.key = this.data.ability.name;
    }

    increaseAbility(increase): void {
        const ability = this.data.ability;
        ability.value += increase;
        if (ability.breakout) {
            if (this.data.abilities[ability.breakout].value >= ability.value) {
                ability.value = this.data.abilities[ability.breakout].value + 1;
            }
            this.updateAbility();
        } else {
            if (ability.value < 1) {
                ability.value = 1;
            }
            for (const key in this.data.abilities) {
                if (this.data.abilities[key].breakout === ability.name) {
                    this.data.abilities[key].value += increase;
                    this.serverService.updateAbility(this.data.serverId, this.data.userId, this.data.characterName, this.data.abilities[key].name, this.data.abilities[key]);
                }
            }
            this.updateAbility();
        }
    }

    addBreakout(): void {
        const ability = this.data.ability;
        this.bottomSheetRef.dismiss();
        const breakout = {
            name: 'New breakout ability',
            value: ability.value + 1,
            breakout: ability.name
        };
        this.serverService.addAbility(this.data.serverId, this.data.userId, this.data.characterName, breakout);
        this.snackBar.open(`${breakout.name} added`, null, {
            duration: 3000,
        });
    }
    removeAbility(): void {
        const ability = this.data.ability;
        this.bottomSheetRef.dismiss();
        this.serverService.removeAbility(this.data.serverId, this.data.userId, this.data.characterName, ability);
        this.snackBar.open(`${ability.name} removed`, null, {
            duration: 3000,
        });
    }

    updateAbility(): void {
        const ability = this.data.ability;
        if (ability.name) {
            this.serverService.updateAbility(this.data.serverId, this.data.userId, this.data.characterName, this.key, ability);
            this.key = ability.name;
        }
    }
}
