// import { Pipe, PipeTransform } from '@angular/core';
// import {Observable, from} from 'rxjs';
// import { filter, flatMap } from 'rxjs/operators';
//
// @Pipe({ name: 'breakout' })
// export class BreakoutPipe implements PipeTransform {
//     transform(value: Observable<any>, keyword: Observable<any>): Observable<any> {
//         // if (!value) {
//         //     return null;
//         // }
//         // console.log(keyword);
//         // console.log(value);
//         const o = from(value);
//         return o.pipe(
//             // flatMap(value => value),
//             filter(item => {
//                 console.log(item);
//                 return item.value.breakout !== keyword;
//             }),
//         );
//         // return value.pipe(filter(item => item.value.breakout === keyword));
//         // return value.filter(item => item.value.breakout === keyword);
//     }
// }
//
// import { Pipe, PipeTransform } from '@angular/core';
// import {Observable, from} from 'rxjs';
// import { filter } from 'rxjs/operators';
//
// @Pipe({ name: 'breakout' })
// export class BreakoutPipe implements PipeTransform {
//     transform(value: Observable<any[]>, keyword: string = '') {
//         if (!value) {
//             return null;
//         }
//         // console.log(keyword);
//         // console.log(value);
//         // return null;
//         // const o = from(value);
//         return value.pipe(filter(item => item.value.breakout !== keyword));
//         // return value.filter(item => item.value.breakout === keyword);
//     }
// }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'breakout' })
export class BreakoutPipe implements PipeTransform {
    transform(value: any, keyword: string = ''): any[] {
        if (!value) {
            return null;
        }
        return value.filter(item => {
            return item.value.breakout === keyword;
        });
    }
}
