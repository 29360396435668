/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'orderValueBy' })
export class OrderValueByPipe implements PipeTransform {

    transform(value: any[], order = '', column: string = ''): any[] {
        if (!value || order === '' || !order) { return value; }
        if (value.length <= 1) { return value; }
        // if (!column || column === '') {
        //     if (order === 'asc') {
        //         return value.sort();
        //     } else {
        //         return value.sort().reverse();
        //     }
        // }

        if (column === 'rune') {
            return orderBy(value, [item => !!item.value[column]], [order]);
        }

        if (column === 'flaw') {
            return orderBy(value, [item => !!item.value[column]], [order]);
        }

        if (column === 'value') {
            return orderBy(value, [item => item.value[column]], [order]);
        }

        return orderBy(value, [item => {
            if (item.value[column]) {
                return item.value[column].toLowerCase();
            } else {
                return item.value[column];
            }
        }], [order]);
        // return orderBy(value, [item => {
        //         // console.log(item.value[column]);
        //     // if (item.value[column] && (item.value[column] === 'string' || item.value[column] instanceof String)) {
        //     //     item.value[column].toLowerCase();
        //     // }
        // }], [order]);
    }
}
