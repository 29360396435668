import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServersComponent } from './servers/servers.component';
import { UsersComponent } from './users/users.component';
import { CharactersComponent } from './characters/characters.component';
import { AbilitiesComponent } from './abilities/abilities.component';

const routes: Routes = [
  { path: 'servers/:serverId/users/:userId/characters/:characterName', component: AbilitiesComponent },
  { path: 'servers/:serverId/users/:userId', component: CharactersComponent },
  { path: 'servers/:serverId', component: UsersComponent },
  { path: 'servers', component: ServersComponent },
  { path: '', redirectTo: 'servers', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
