import { Component, OnInit } from '@angular/core';
import { ServerService } from './server.service';

@Component({
  selector: 'app-servers',
  templateUrl: './servers.component.html',
  styleUrls: ['./servers.component.scss']
})
export class ServersComponent implements OnInit {

  servers = null;

  constructor(
      private serverService: ServerService
  ) {
    this.servers = this.serverService.getServers();
  }

  ngOnInit() { }

}



