import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ServerService {

    servers: Observable<any>;
    db: AngularFireDatabase;

    constructor(db: AngularFireDatabase) {
        this.db = db;
        this.servers = this.db.object('/servers').valueChanges();
    }

    getServers() {
        return this.servers;
    }

    getServer(serverId) {
        return this.db.object('/servers/' + serverId + '/users').valueChanges();
    }

    getUser(serverId, userId) {
        return this.db.object('/servers/' + serverId + '/users/' + userId).valueChanges();
    }

    getCharacters(serverId, userId) {
        return this.db.object('/servers/' + serverId + '/users/' + userId + '/characters').valueChanges();
    }

    getAbilities(serverId, userId, characterName) {
        return this.db.object('/servers/' + serverId + '/users/' + userId + '/characters/' + characterName + '/abilities').valueChanges();
    }

    addAbility(serverId, userId, characterName, ability) {
        this.db.object('/servers/' + serverId + '/users/' + userId + '/characters/' + characterName + '/abilities/' + ability.name).set(ability);
    }

    removeAbility(serverId, userId, characterName, ability, key = null) {
        const path = '/servers/' + serverId + '/users/' + userId + '/characters/' + characterName + '/abilities/' + (key ? key : ability.name);
        this.db.object(path).remove();
    }

    updateAbility(serverId, userId, characterName, key, ability) {
        if (key !== ability.name) {
            this.removeAbility(serverId, userId, characterName, null, key);
            this.addAbility(serverId, userId, characterName, ability);
        } else {
            this.db.object('/servers/' + serverId + '/users/' + userId + '/characters/' + characterName + '/abilities/' + key).update(ability);
        }
    }


}
