import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keyword' })
export class KeywordPipe implements PipeTransform {
    transform(value: any[]): any[] {
        if (!value) {
            return null;
        }
        return value.filter(item => {
            return !item.value.breakout;
        });
    }
}
